import React from 'react';

const Landing = () => {
  const docLink = 'https://docs.rebel.game/';
  return (
    <div
      className="flex flex-col items-center xl:items-start space-y-[4.4444vw] xl:space-y-[1.5625vw] pt-[18.34975%] px-10 sm:px-[82px] xl:p-[7.8125vw]">
      <div className="flex items-center space-x-6 xl:space-x-4">
        <img className="w-[8.5333vw] xl:w-[2.5vw]" src="images/logo.svg" alt="" />
        <span
          className="font-bold font-Inter text-[6.25vw] lg:text-[90px] xl:text-[2.34375vw] leading-[120%]">Rebel DAO</span>
      </div>
      <div className="flex flex-col space-y-4">
        <span
          className="font-semibold text-center xl:text-left text-[8vw] lg:text-[90px] xl:text-[2.9296875vw] leading-[120%] tracking-[0.05em] xl:max-w-[36.1328125vw]">Lead the Charge Against Centralized Oppression</span>
        <span
          className="hidden xl:block font-semibold text-[1.015625vw] leading-[192.3076%] tracking-[0.05em] max-w-[39.296875vw] capitalize">Rebel DAO is a decentralized financial game uniting players worldwide to combat financial centralization, fostering an ecosystem of financial freedom and collaboration in the DeFi revolution.</span>
      </div>
      <div className="flex items-center space-x-[4.8373vw] xl:space-x-[2.12578125vw]">
        <div className="flex items-center space-x-[1.7262vw] xl:space-x-[0.75859375vw]">
          <a href={docLink} target="_blank" rel="noreferrer">
            <span
              className="font-medium text-[4vw] xl:text-[1.7578125vw] leading-[120%] tracking-[0.05em]">Rebel Docs</span>
          </a>
          <a href={docLink} target="_blank" rel="noreferrer">
            <img className="w-[4vw] h-[4vw] xl:w-[1.7578125vw] xl:h-[1.7578125vw]" src="images/next.svg" alt="" />
          </a>
        </div>
        <div className="flex items-center space-x-[1.7262vw] xl:space-x-[0.75859375vw]">
          <span
            className="font-medium text-[4vw] xl:text-[1.7578125vw] leading-[120%] tracking-[0.05em]">Rebel Video</span>
          <img className="w-[4vw] h-[4vw] xl:w-[1.7578125vw] xl:h-[1.7578125vw]" src="images/next.svg" alt="" />
        </div>
      </div>
      <div className="flex items-center space-x-[3.6458vw] xl:space-x-9">
        <a href="https://twitter.com/the_rebel_dao" target="_blank" rel="noreferrer">
          <img className="w-[5.2083vw] h-[5.2083vw] xl:w-[1.7vw] xl:h-[1.7vw]" src="images/socials/twitter.svg"
               alt="" />
        </a>
        <a href="https://t.me/rebeldao" target="_blank" rel="noreferrer">
          <img className="w-[5.2083vw] h-[5.2083vw] xl:w-[1.7vw] xl:h-[1.7vw]" src="images/socials/telegram.svg"
               alt="" />
        </a>
        <img className="w-[5.2083vw] h-[5.2083vw] xl:w-[1.7vw] xl:h-[1.7vw]" src="images/socials/medium.svg" alt="" />
      </div>
    </div>
  );
};

export default Landing;
