import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "../hooks/useDimensions";

const Layout = ({ children }) => {
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  
  useMemo(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if(screenWidth > 0 && screenHeight > 0) appHeight()
  }, [screenWidth, screenHeight])
  
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [])
  
  return (
    <div className="flex flex-col">
      <video className="absolute top-0 left-0 hidden xl:block w-full h-full object-cover" autoPlay loop muted playsInline controls={false}>
        <source src="videos/Coming Soon Rebel Desktop.mp4" type="video/mp4" />
      </video>
      <video className="absolute top-0 left-0 block xl:hidden w-full h-full object-cover" autoPlay loop muted playsInline controls={false}>
        <source src="videos/Coming Soon Rebel Mobile.mp4" type="video/mp4" />
      </video>
      <main className="flex flex-col flex-1 z-1 relative">{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
